<ul class="align-center nav-links" [ngClass]="inPanel ? 'panel-links main-layout' : ''">
  <li class="nav-item" *ngIf="isUserRoleUser">
    <a
      class="nav-link"
      routerLink="/vacation"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{ exact: false }"
      id="vacation-menu"
    >
      {{ 'NAV_BAR.VACATION' | translate }}
    </a>
  </li>

  <li class="nav-item dropdown" *ngIf="isUserRoleUser">
    <a
      class="nav-link dropdown"
      id="dreamix"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [matMenuTriggerFor]="dreamixMenu"
      [class.active]="router.isActive(router.parseUrl('/dreamix'), false)"
    >
      DREAMIX
    </a>
    <mat-menu #dreamixMenu="matMenu">
      <div aria-labelledby="dreamix">       
        <ng-container *ngFor="let page of allPages">
          <ng-container class="dreamix-menu" *ngIf="hasVisibleChildren(page) && page.visible">
            <button
              mat-menu-item
              [matMenuTriggerFor]="dreamixSubMenu"
              routerLinkActive="active"
              [class.active]="router.isActive(router.parseUrl('/dreamix/page/' + page.slug), false)"
              [routerLink]="['/dreamix/page', page.slug]"
            >
              {{ page.title }}
            </button>
            <mat-menu #dreamixSubMenu="matMenu">
              <ng-container *ngFor="let child of page.children">
                <button
                  mat-menu-item
                  *ngIf="child.visible"
                  routerLinkActive="active"
                  [routerLink]="['/dreamix/page', child.slug]"
                >
                  {{ child.title }}
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="!hasVisibleChildren(page) && page.visible">
            <button
              mat-menu-item
              routerLinkActive="active"
              [routerLink]="['/dreamix/page', page.slug]"
            >
              {{ page.title }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </mat-menu>
  </li>
  <li class="nav-item dropdown" *ngIf="isUserRoleAdmin">
    <a
      class="nav-link dropdown"
      id="admin"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [matMenuTriggerFor]="adminMenu"
      [class.active]="router.isActive(router.parseUrl('/admin'), false)"
    >
      {{ 'NAV_BAR.ADMIN' | translate }}
    </a>
    <mat-menu #adminMenu="matMenu">
      <div aria-labelledby="admin">
        <button mat-menu-item routerLinkActive="active" routerLink="admin/events">
          {{ 'BREADCRUMBS.EVENTS' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/requests">
          {{ 'NAV_BAR.REQUESTS' | translate }}
        </button>
        <button
          *ngIf="isTalentsAndCultureEmployee()"
          mat-menu-item
          routerLinkActive="active"
          routerLink="/admin/office-space"
          >
          {{ 'NAV_BAR.OFFICE_SPACE' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="reports">Reports</button>
        <mat-menu #reports="matMenu">
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/travel-requests"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.ADMIN_TRAVEL_REQUESTS' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/overtimes"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.OVERTIMES' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/reimbursements"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'ADMIN.OVERTIMES.REIMBURSEMENTS' | translate }}
          </button>
          <button mat-menu-item routerLinkActive="active" routerLink="/admin/export-hours">
            {{ 'NAV_BAR.EXPORT_TIME' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="/admin/days-left"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.DAYS_LEFT' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/utilization-rates"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.UTILIZATION_RATES' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/headcount"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.HEADCOUNT' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/work-days"
            *ngIf="isUserRoleOperationsAdmin"
          >
            {{ 'NAV_BAR.WORK_DAYS' | translate }}
          </button>
        </mat-menu>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/projects">
          {{ 'NAV_BAR.PROJECTS' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/employees">
          {{ 'NAV_BAR.EMPLOYEES' | translate }}
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="administration"
          *ngIf="isUserRoleAdmin || isUserRoleSuperAdmin"
        >
          Administration
        </button>
        <mat-menu #administration="matMenu">
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/holidays"
            *ngIf="isUserRoleAdmin"
          >
            {{ 'NAV_BAR.HOLIDAYS' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/certificates"
            *ngIf="isUserRoleAdmin"
          >
            {{ 'NAV_BAR.CERTIFICATES' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/positions"
            *ngIf="isUserRoleAdmin"
          >
            {{ 'NAV_BAR.POSITIONS' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/skills"
            *ngIf="isUserRoleAdmin"
          >
            {{ 'NAV_BAR.SKILLS' | translate }}
          </button>
          <button
            mat-menu-item
            routerLinkActive="active"
            routerLink="admin/domains"
            *ngIf="isUserRoleSuperAdmin"
          >
            {{ 'NAV_BAR.DOMAINS' | translate }}
          </button>
        </mat-menu>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/pages">
          {{ 'NAV_BAR.PAGES' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/holidays">
          {{ 'NAV_BAR.HOLIDAYS' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/dreamixBirthdays">
          {{ 'NAV_BAR.DREAMIX_BIRTHDAYS' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/licenses">
          {{ 'NAV_BAR.LICENSES' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/admin/referrals">
          {{ 'NAV_BAR.REFERRALS' | translate }}
        </button>
      </div>
    </mat-menu>
  </li>

  <li class="nav-item dropdown" *ngIf="isUserRoleLead">
    <a
      class="nav-link dropdown"
      id="lead"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [matMenuTriggerFor]="leadMenu"
      [class.active]="router.isActive(router.parseUrl('/lead'), false)"
    >
      {{ 'NAV_BAR.LEAD' | translate }}
    </a>
    <mat-menu #leadMenu="matMenu">
      <div aria-labelledby="lead">
        <button mat-menu-item routerLinkActive="active" routerLink="/lead/export-hours">
          {{ 'NAV_BAR.EXPORT_TIME' | translate }}
        </button>
        <button mat-menu-item routerLinkActive="active" routerLink="/lead/projects">
          {{ 'NAV_BAR.PROJECTS' | translate }}
        </button>
      </div>
    </mat-menu>
  </li>
  <li class="nav-item dropdown" *ngIf="inPanel">
    <a
      class="nav-link dropdown"
      id="user"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [matMenuTriggerFor]="profileMenu"
      [class.active]="router.isActive(router.parseUrl('/user'), false)"
    >
      {{ this.user.firstNameEn }}
    </a>
    <mat-menu #profileMenu="matMenu">
      <app-user-nav-links [user]="user"></app-user-nav-links>
    </mat-menu>
  </li>
</ul>
